// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-artworks-js": () => import("./../../../src/pages/artworks.js" /* webpackChunkName: "component---src-pages-artworks-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-contentful-art-work-slug-js": () => import("./../../../src/pages/{ContentfulArtWork.slug}.js" /* webpackChunkName: "component---src-pages-contentful-art-work-slug-js" */),
  "component---src-pages-contentful-blog-slug-js": () => import("./../../../src/pages/{ContentfulBlog.slug}.js" /* webpackChunkName: "component---src-pages-contentful-blog-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */)
}

